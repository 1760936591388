import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import { profile } from '../components/profile/profile.module.css';
import { OutboundLink } from 'gatsby-plugin-google-analytics';
import Menu from '../components/menu';

interface Props {
    data: {
        profileImage;
        resume: {
            publicURL;
        };
    };
}

const App: React.FunctionComponent<Props> = ({ data }: Props) => (
    <div className="application">
        <Menu />
        <div className="page-container">
            <div className="about-wrapper">
                <header>
                    <GatsbyImage
                        className={profile}
                        image={getImage(data.profileImage)}
                        alt="profile image"
                    ></GatsbyImage>
                </header>
                <section className="about-section">
                    <h1>hello! my name is mathias</h1>
                    <p>
                        I am a Software Engineer and Designer. I use tech and art in pursuit of improving the life of
                        myself and others. Currently I am taking some time off to find opportunities where I best can
                        employ my skills and interests in alignment with my goals. Please feel free to reach out if you
                        see me fit in your work’s mission, as I am both open to job positions and collaboration.
                        <br />
                        <br />
                        Until recently I was employed as a consultant at{' '}
                        <OutboundLink href="https://www.netcompany.com">netcompany</OutboundLink>, building central
                        solutions for major departments within the Danish public sector. During my time as a consultant,
                        I had multiple responsibilities in different areas of my assigned project. Amongst those I have,
                        in a team of 2-3, designed, implemented and tested a stable self service portal which will have
                        over 100.000 unique monthly users.
                        <br />
                        <br />I obtained my degree in Computer Science through a joint programme between{' '}
                        <OutboundLink href="https://www.dtu.dk">dtu</OutboundLink> and{' '}
                        <OutboundLink href="https://www.kaist.ac.kr/">kaist</OutboundLink> in the summer of 2019. With a
                        strong focus on Human-Computer Interaction, I participated in several classes and projects
                        developing user-friendly solutions aimed to better people's lives. I had the opportunity to
                        conduct my thesis research with Juho Kim at Kixlab.org, where I designed and tested a prototype
                        to evaluate how learners can benefit from reviewing each other's individual lesson plans.
                    </p>
                    <h3>find me elsewhere</h3>
                    <p>
                        - <a href={data.resume.publicURL}>resume</a>
                        <br />- <OutboundLink href="https://linkedin.com/in/sixped">linkedin</OutboundLink>
                        <br />- <OutboundLink href="https://github.com/matjias">github</OutboundLink>
                        <br />- <OutboundLink href="https://instagram.com/sixped.photo/">instagram</OutboundLink>
                        <br />- <OutboundLink href="mailto:sixped@gmail.com">email</OutboundLink>
                    </p>
                </section>
            </div>

            <footer>
                <p>&copy; 2021 Mathias Sixten Pedersen</p>
            </footer>
        </div>
    </div>
);

App.displayName = 'mathiassixten';

export default App;

export const query = graphql`
    query {
        profileImage: file(relativePath: { eq: "images/profile.jpg" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP, AVIF])
            }
        }
        resume: file(relativePath: { eq: "documents/mathiassixtenresume.pdf" }) {
            publicURL
        }
    }
`;
